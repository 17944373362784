<!--
 * @FilePath: \zhishe-pc\src\views\personalCenter\componts\MessageAllList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-29 19:15:55
-->
<template>
  <div class="allMessageList">
    <div class="messageItem" v-for="(item,index) in messageList" :key="index">
      <div class="avatar">
        <img :src="item.avatar" alt="">
      </div>
      <div class="messageInfo">
        <div class="messageTitle">
          <p class="f14 f0404 f_b">{{ item.systemAuthor }}</p>
          <p class="time">{{ item.addTime }}</p>
        </div>
        <div class="messageTxt">
          <span v-show="item.is_view == 0">*</span>
          {{item.title}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageAllList",
  props:['messageList']
}
</script>

<style scoped>
.messageInfo{
  flex: 1;
  padding-bottom: 0.3rem;
  border-bottom: 0.01rem solid #EEEEEE;
}
.messageTxt span{
  color: #F56C6C;
  width: 0.02rem;
  height: 0.02rem;
  margin-right: 4px;
}

.messageTxt{
  margin-top: 0.2rem;
  width: 7.4rem;
  font-size: 0.14rem;
  color: #999999;
}
.time{
  font-size: 0.12rem;
  color:#999999;
}
.messageTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageItem{
  display: flex;
  margin-top: 0.3rem;
}
.avatar{
  width: 0.44rem;
  height: 0.44rem;
  /*background: coral;*/
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.2rem;
}
.avatar img{
  width: 100%;
}
</style>