<template>
  <div class="messageBox">
    <div class="seleteBox">
      <el-tabs v-model="activeType" @tab-click="handleClick">
        <el-tab-pane label="站内公告" name="2" lazy></el-tab-pane>
        <el-tab-pane label="审核消息" name="3" lazy> </el-tab-pane>
        <el-tab-pane label="我的评论" name="4" lazy> </el-tab-pane>
      </el-tabs>
      <div class="allDispose" v-show="activeType == 3" @click="clearMessage">
        全部标记已读
      </div>
    </div>
    <div class="messageContent">
      <MessageLetterList
        :messageList="messageList2"
        v-show="activeType == 2"
      ></MessageLetterList>
      <MessageAllList
        :messageList="messageList3"
        v-show="activeType == 3"
      ></MessageAllList>
      <MessageCommentList
          @refreshComment="refreshMsg"
        :messageList="messageList4"
        v-show="activeType == 4"
      ></MessageCommentList>
    </div>
    <div class="t_c mt20">
      <el-pagination
        background
        layout="prev, pager, next"
        v-show="total > 0"
        @current-change="setPage"
        :total="total"
        :current-page="page"
        :page-size="10"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import MessageAllList from "./componts/MessageAllList";
import MessageCommentList from "./componts/MessageCommentList";
import MessageLetterList from "./componts/MessageLetterList";
import {
  getAuditMessage,
  getMessageAndGive,
  letterList,
  setMsgView,
} from "../../Api";
import { getToken } from "@/utils/auth";
export default {
  name: "MyMessage",
  components: {
    MessageAllList,
    MessageCommentList,
    MessageLetterList,
  },
  data() {
    return {
      activeType: "2",
      page: 1,
      messageList2: [],
      messageList3: [],
      messageList4: [],
      total: 0,
    };
  },
  methods: {
    //清空未读
    clearMessage() {
      setMsgView({ token: getToken() }).then((res) => {
        if (res.data.msg == "操作成功") {
          this.setPage(1);
        }
      });
    },
    setPage(val) {
      this.page = val;
      if (this.activeType === "2") {
        this.getLetterList();
      } else if (this.activeType === "3") {
        this.getAuditMessage();
      } else if (this.activeType === "4") {
        this.getMessageAndGive();
      }
    },
    handleClick() {
      this.page = 1;
      this.total = 0;
      if (this.activeType === "2") {
        this.getLetterList();
      } else if (this.activeType === "3") {
        this.getAuditMessage();
      } else if (this.activeType === "4") {
        this.getMessageAndGive();
      }
    },
    //  获取审核消息
    getAuditMessage() {
      const data = {
        page: this.page,
        token: getToken(),
      };
      getAuditMessage(data).then((res) => {
        this.total = res.data.total;
        this.messageList3 = res.data.data;
      });
    },
    refreshMsg(){
      this.getMessageAndGive()
    },
    //  获取品论和点赞
    getMessageAndGive() {
      const data = {
        page: this.page,
        token: getToken(),
      };
      getMessageAndGive(data).then((res) => {
        this.total = res.data.total;
        this.messageList4 = res.data.data;
      });
    },
    // 获取站内公告
    getLetterList() {
      const data = {
        page: this.page,
        token: getToken(),
      };
      letterList(data).then((res) => {
        this.total = res.data.total;
        this.messageList2 = res.data.data;
      });
    },
  },
  created() {
    this.setPage(1);
  },
};
</script>

<style scoped>
.messageBox {
  background: #ffffff;
  border-radius: 0.04rem;
  padding: 0.2rem 0.3rem;
}
.allDispose {
  font-size: 0.14rem;
  color: #666666;
  border-radius: 0.04rem;
  background: #eeeeee;
  width: 1.24rem;
  height: 0.32rem;
  text-align: center;
  line-height: 0.32rem;
  cursor: pointer;
}
.seleteBox >>> .el-tabs__header {
  margin: 0;
}
.seleteBox >>> .el-tabs__item {
  height: 00.4rem;
  line-height: 00.4rem;
  color: #999999;
}
.seleteBox >>> .el-tabs__active-bar {
  background-color: #040404;
  bottom: 1px;
}
.seleteBox >>> .el-tabs__item.is-active {
  color: #040404;
}
.seleteBox >>> .el-tabs__item:hover {
  color: #040404;
  cursor: pointer;
}
.seleteBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  line-height: 0.6rem;
  height: 0.6rem;
}
.seleteBox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e4e7ed;
  z-index: 1;
}
</style>