<template>
  <div class="allMessageList">
    <div class="messageItem" v-for="(item,index) in messageList" :key="index">
      <div class="avatar">
        <img v-if="item.fromUser.photo" :src="item.fromUser.photo" alt="">
      </div>
      <div class="messageInfo">
        <div class="messageTitle">
          <p class="f14 f0404 f_b">{{ item.fromUser.name }}</p>
          <p class="time">{{ item.addtime }}</p>
        </div>
        <div v-if="item.type==='zan'">
          <div class="messageTxtBox">
            <div class="messageTxt">
              赞了你的{{item.title}}
            </div>
          </div>
        </div>
        <div v-if="item.type==='cmt'">
          <div class="messageTxtBox">
            <div class="messageTxt">
              {{ item.content }}
            </div>
            <div class="huifu" v-if="item.replay.length === 0 && showCmt !== index" @click="showCmtFun(index,null, 'add')">
              <i class="iconfont f22 mr8">&#xe69a;</i>
              <span class="f14">回复</span>
            </div>
          </div>
          <div class="huifuInput" v-show="showCmt == index">
            <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="textarea">
            </el-input>
            <div class="huifuBut" @click="submitCmt(item)">回复</div>
          </div>
          <div v-if="item.replay.length > 0 && showCmt !== index">
            <div class="huifuTxt" v-for="(replayItem, rIndex) in item.replay" :key="rIndex">
              <div>
            {{ replayItem.name }}:{{replayItem.content}}
          </div>
            <div @click="showCmtFun(index,replayItem.content,'edit',replayItem.id)">
              <i class="iconfont f22 mr8">&#xe69a;</i>
              <span class="f14" style="cursor: pointer">修改回复</span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import { publishComment} from "../../../Api";
export default {
  name: "MessageCommentList",
  props:['messageList'],
  // commentId: 14225
  // content: 33
  // pid: fWMdMgbuX
  // token: 581503078bdf48d98f2ff5bb4bbc32c9
  data(){
    return {
      textarea:'',
      showCmt:null,
      id: '',
      type: 'add',
      replayId: ''
    }
  },
  methods:{
    showCmtFun(index,content,type,replayId){
      this.showCmt = index
      this.textarea =  content
      this.type = type
      this.replayId = replayId
    },
    submitCmt(data){
      let params = {}
      if(this.type === 'add') {
        params = {
          commentId: data.commentID,
          content: this.textarea,
          pid: data.infoID,
          token: getToken(),
          edit: 0
        }
      }
      if(this.type === 'edit') {
        params = {
          commentId:this.replayId,
          content: this.textarea,
          token: getToken(),
          edit: 1,
        }
      }
      publishComment(params).then(res=>{
        if(res.status === '200' && res.data.message === "addSuccess"){
          this.$message.success('回复成功')
          this.showCmt = null
          this.textarea=''
          this.replayId = ''
          this.type = ''
          this.$emit('refreshComment')
        }
      })
      // const params = {
      //   commentId: data.commentID,
      //   content: this.textarea,
      //   pid:data.infoID,
      //   token:getToken()
      // }
      // publishComment(params).then(res=>{
      //   if(res.status === '200' && res.data.message === "addSuccess"){
      //     this.$message.success('回复成功')
      //     this.showCmt = null
      //     this.textarea=''
      //     this.$emit('refreshComment')
      //   }
      // })
    }
  },
  watch:{
    messageList(){
      this.showCmt = null
    }
  }
}
</script>

<style scoped>
.huifuTxt{
  width: 100%;
  margin-top: 0.2rem;
  padding: 0.1rem;
  border-radius: 0.04rem;
  background: #F7F6FB;
  color: #040404;
  font-size: 0.12rem;
  display: flex;
  justify-content: space-between;
}
.huifuBut{
  background: #F46600;
  padding: 0.05rem 0;
  border-radius: 0.04rem;
  color: #FFFFFF;
  font-size: 0.14rem;
  margin-top: 0.3rem;
  width: 0.7rem;
  text-align: center;
  margin-left: auto;
  cursor: pointer;
}
.huifuInput{
  margin-top: 0.2rem;
  /*width: 7.4rem;*/
  width: 100%;
}
.huifu{
  display: flex;
  align-items: center;
  color: #040404;
  cursor: pointer;
}
.messageTxtBox{
  display: flex;
  justify-content: space-between;
}
.messageInfo{
  flex: 1;
  padding-bottom: 0.3rem;
  border-bottom: 0.01rem solid #EEEEEE;
}
/* .messageTxt:before{
  content: '*';
  color: #F56C6C;
  width: 0.02rem;
  height: 0.02rem;
  margin-right: 4px;
} */

.messageTxt{
  margin-top: 0.2rem;
  width: 7.4rem;
  font-size: 0.14rem;
  color: #999999;
}
.time{
  font-size: 0.12rem;
  color:#999999;
}
.messageTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageItem{
  display: flex;
  margin-top: 0.3rem;
}
.avatar{
  width: 0.44rem;
  height: 0.44rem;
  background: coral;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.2rem;
}
.avatar img{
  width: 100%;
}
</style>