<!--
 * @FilePath: \zhishe-pc\src\views\personalCenter\componts\MessageLetterList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-29 18:48:56
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-29 18:55:59
-->
<template>
  <div class="allMessageList">
    <div class="messageItem" v-for="(item,index) in messageList" :key="index">
      <div class="messageInfo">
        <div class="messageTitle">
          <p class="f14 f0404 f_b">系统公告</p>
          <p class="time">{{ item.addtime }}</p>
        </div>
        <div class="messageTxt">
          {{item.content}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageLetterList",
  props:['messageList']
}
</script>

<style scoped>
.messageInfo{
  flex: 1;
  padding-bottom: 0.3rem;
  border-bottom: 0.01rem solid #EEEEEE;
}
/* .messageTxt:before{
  color: #F56C6C;
  width: 0.02rem;
  height: 0.02rem;
  margin-right: 4px;
} */

.messageTxt{
  margin-top: 0.2rem;
  font-size: 0.14rem;
  color: #999999;
}
.time{
  font-size: 0.12rem;
  color:#999999;
}
.messageTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messageItem{
  display: flex;
  margin-top: 0.3rem;
}
.avatar{
  width: 0.44rem;
  height: 0.44rem;
  /*background: coral;*/
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.2rem;
}
.avatar img{
  width: 100%;
}
</style>